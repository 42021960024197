import {
  Card, CardActionArea, CardMedia, CardContent, Container,
  List, ListItem,
  Typography,
  Theme,
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";

import React, {
  ReactElement, useEffect, Suspense,
} from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { useInView } from "react-intersection-observer";

import {
  useShowcaseSelector,
  useCurrentContentIdSelector
} from "./selectors";
import { IshowcaseContent } from "./reducer";
import { useSendShowcaseEvent, useSetCurrentContentId } from "./hooks";
import { showcaseEventEnum } from "./actions";

import { getFileIcon } from "../utils/fileTypeIcon";
import { AssetPreview } from "../folder";
import { useAssetStatsSelector } from "../folder/selectors";
import { smallScreenBreakProint } from "../selectors";

type ShowcaseParamsType = {
  showcaseId: string;
}
type ShowcaseContentComp = RouteComponentProps<ShowcaseParamsType> & {
  item: IshowcaseContent;
  sendShowcaseEvent: ReturnType<typeof useSendShowcaseEvent>;
}

const useContentCardStyles= makeStyles((theme: Theme) => createStyles({
  card: {
    width: "100%",
  },
  cardMedia: {
    maxHeight: "180px",
    objectFit: "contain",
    [theme.breakpoints.up(smallScreenBreakProint)]: {
      maxHeight: "360px",
    }
  }
}));

interface IcontentCard {
  media: string;
  primaryTitle: string;
  secondaryTitle: string;
  onClick: () => void;
}
function ContentCard(props: IcontentCard): ReactElement {
  const classes = useContentCardStyles({});
  const {
    media, primaryTitle, secondaryTitle,
    onClick
  } = props;

  return (
    <Card elevation={1} className={classes.card} raised>
      <CardActionArea onClick={onClick}>
        <CardMedia component="img" src={media || getFileIcon(".blank")}
          className={classes.cardMedia}
        />
        <CardContent>
          <Typography align="left" variant="h6" component="h2">
            {primaryTitle}
          </Typography>
          <Typography align="left" variant="subtitle2" color="textSecondary">
            {secondaryTitle}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

function linkVisitEvents(props: ShowcaseContentComp): void {
  const { sendShowcaseEvent, item: {id, title} } = props;

  sendShowcaseEvent(
    showcaseEventEnum.View,
    {properties: { id, title, type: "link" }}
  );
}


function ContentLink(props: ShowcaseContentComp): React.ReactElement {
  const {
    item, history, match:{params:{showcaseId}},
  } = props;
  const { t } = useTranslation();

  const onClick = (id: string) => (): void => {
    linkVisitEvents(props);
    history.push(`${showcaseId}/${item.type}/${id}`);
  };

  return (
    <ContentCard
      media={item.thumbnail}
      primaryTitle={item.title}
      secondaryTitle={t(item.type)}
      onClick={onClick(item._id)}
    />
  );
}

function UnSupportLinkCard(props: ShowcaseContentComp): ReactElement {
  const { item: {href, title} } = props;
  const { t } = useTranslation();
  const onClick = (): void => {
    linkVisitEvents(props);
    window.open(href);
  };

  return (
    <ContentCard
      media={getFileIcon(".link")}
      primaryTitle={title}
      secondaryTitle={t("link")}
      onClick={onClick}
    />
  );
}

const useAssetItemStyles = makeStyles(() => createStyles({
  root: {
    margin: "auto"
  }
}));
function AssetItem(props: ShowcaseContentComp): React.ReactElement {
  const { item, sendShowcaseEvent } = props;
  const {id, title, asset } = item;
  const classes = useAssetItemStyles({});
  const assetStats = useAssetStatsSelector(asset._id);

  useEffect(() => {
    if (assetStats) {
      if (assetStats.currentPage) {
        sendShowcaseEvent(showcaseEventEnum.ViewedByPage, {properties: {
          id, title, type: "file",
          page: assetStats.currentPage,
          duration: assetStats.pages[assetStats.currentPage].watchTime,
        }});
      } else {
        sendShowcaseEvent(showcaseEventEnum.View, {properties: {
          id, title, type: "file",
          duration: assetStats.watchTime,
        }});
      }
    }
  }, [assetStats]);

  return (
    <AssetPreview {...item} classes={classes} />
  );
}

function ShowcaseContentComp(props: ShowcaseContentComp): ReactElement {
  const [ ref, inView, entry ] = useInView({
    rootMargin: `-${window.innerHeight / 3}px`
  });
  const currentContentId = useCurrentContentIdSelector();
  const setCurrentContentId = useSetCurrentContentId();

  const { item } = props;
  const maps = {
    article: ContentLink,
    categories: ContentLink,
    product: ContentLink,
    file: AssetItem,
    link: UnSupportLinkCard,
  };

  useEffect(() => {
    if (entry && !inView && (currentContentId === item.id)) {
      window.scrollTo({
        top: entry.target["offsetTop"] - 10,
        behavior: "smooth"
      });
    }
  }, [currentContentId]);

  useEffect(() => {
    if (entry && inView
      && currentContentId && (currentContentId !== item.id)) {
      setCurrentContentId(item.id);
    }
  }, [inView]);

  const Comp = maps[item.type];
  return (
    <Suspense key={item.id} fallback="loading...">
      <ListItem disableGutters dense ref={ref}>
        <Comp {...props} />
      </ListItem>
    </Suspense>
  );
}

export default function Contents(props: RouteComponentProps<ShowcaseParamsType>): React.ReactElement {
  const showcase = useShowcaseSelector();
  const sendShowcaseEvent = useSendShowcaseEvent(props.match.params.showcaseId);

  useEffect(() => {
    sendShowcaseEvent(showcaseEventEnum.Visit);
  }, []);

  if (!showcase) {
    return null;
  }

  return (
    <Container maxWidth="md">
      <List>
        {showcase.content.map((item) => {
          return <ShowcaseContentComp key={item.id}
            {...props}
            item={item}
            sendShowcaseEvent={sendShowcaseEvent}
          />;
        })}
      </List>
    </Container>
  );
}
