export const MODULE_NAME = "showcase";

export const SET_CURRENT_CONTENT_ID = "showcase/set_current_id";
export const LOAD_SHOWCASE_REQUEST = "showcase/load/request";
export const LOAD_SHOWCASE_SUCCESS = "showcase/load/success";
export const LOAD_SHOWCASE_FAILURE = "showcase/load/failure";
export const TOGGLE_DRAWER = "showcase/toggle_drawer";

export function getShowcase(showcaseId) {
  return (dispatch, getState, {actions, api}): void => {
    api.fetchShowcase(showcaseId)
    .then((res) => {
      dispatch(actions.setTenantId(res.tenant._id));
      dispatch({ type: LOAD_SHOWCASE_SUCCESS, payload: res });
    });
  };
}

export function setCurrentContentId(id: string) {
  return (dispatch): void => {
    dispatch({type: SET_CURRENT_CONTENT_ID, payload: id});
  };
}

export function toggleDrawer() {
  return (dispatch): void => {
    dispatch({ type: TOGGLE_DRAWER });
  };
}

export enum showcaseEventEnum {
  Visit = "visit",
  View = "view",
  ViewedByPage = "viewed_by_page",
}
export interface IshowcaseEventData {
  context?: {
    router_id?: string;
  };
  properties: {
    asset?: {
      _id: string;
    };
    duration?: number;
    id: string;
    type: string;
    title: string;
    page?: number;
  };
}

export function showcaseEvent(sid: string, type: showcaseEventEnum, data?: IshowcaseEventData) {
  return (dispatch, getState, { api }): void => {
    api.showcaseEvent(sid, type, data);
  };
}
