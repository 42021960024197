import {
  AppBar,
  Box,
  Collapse,
  Divider,
  List, ListItem, ListItemText,
  Tabs, Tab, Theme, ListItemAvatar, Avatar,
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router";

import { getProduct } from "./actions";
import { useProductSelector } from "./selectors";
import { Iproduct } from "./reducer";

import {
  useTenantIdSelector,
  useSmallScreen,
  afterSmallScreenBreakProint,
  smallScreenBreakProint,
} from "../selectors";
import { openImageDialog } from "../actions";
import { useOpenAssetDetail } from "../hooks";
import { useLoadAssetByIds } from "../folder/hooks";
import RichContent from "../components/richContent";

interface IuseStyle {
  isSmallScreen?: boolean;
}
const useStyles = makeStyles((theme: Theme) => {
  const height = `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`;

  return createStyles({
    cover: {
      margin: "auto",
      maxWidth: "100%",
      maxHeight: "100%",
      padding: theme.spacing(2),
    },
    main: {
      backgroundColor: theme.palette.common.white,
      minHeight: height,
      [theme.breakpoints.up(afterSmallScreenBreakProint)]: {
        display: "flex",
        flexDirection: "row",
        height,
      },
    },
    imageContainer: {
      display: "flex",
      flexDirection: "column",
      height: "40%",
      [theme.breakpoints.up(afterSmallScreenBreakProint)]: {
        height,
        width: "50%"
      },
    },
    tabContainer: ({isSmallScreen}: IuseStyle) => ({
      display: "flex",
      flexDirection: "column",
      height: isSmallScreen ? "60%" : height,
      width: isSmallScreen ? "100%" : "50%",
    }),
    tabs: {
      [theme.breakpoints.down(smallScreenBreakProint)]: {
        ...theme.mixins.toolbar,
      },
    },
    tabsFlexContainer: {
      height: "100%"
    },
  });
});

function useGetProduct(pid: string): Iproduct {
  const dispatch = useDispatch();
  const tenantId = useTenantIdSelector();
  const product = useProductSelector(pid);

  useEffect(() => {
    if (tenantId && pid) {
      dispatch(getProduct(tenantId, pid));
    }
  }, [tenantId, pid]);

  return product;
}


interface IproductTabs {
  product: Iproduct;
  openImage: (src: string, alt: string) => void;
}
function ProductTabs({product}: IproductTabs): React.ReactElement {
  const [tabIndex, setTabIndex] = useState(0);

  const classes = useStyles({});
  const descriptions = {
    title: "产品概览",
    type: "content",
    key: 0,
    content: product.description,
    groups: []
  };
  const contents = [descriptions].concat(product.addition.map((pa, index) => {
    return Object.assign({key: index + 1}, pa);
  }));


  return (
    <>
      <AppBar position="sticky" color="secondary" elevation={0}>
        <Tabs variant="scrollable" scrollButtons="auto"
          textColor="primary" indicatorColor="primary"
          className={classes.tabs}
          classes={{flexContainer: classes.tabsFlexContainer}}
          value={tabIndex}
        >
          {contents.map((c) => <Tab
            key={c.key} value={c.key} label={c.title}
            onClick={(): void => setTabIndex(c.key)}
          />)}
        </Tabs>
      </AppBar>
      {contents[tabIndex].content
        && <RichContent content={contents[tabIndex].content} />
      }
      {!_.isEmpty(contents[tabIndex].groups)
        && <RelationAssets groups={contents[tabIndex].groups}/>
      }
    </>
  );
}

const useRelationAssetsStyle = makeStyles(() => createStyles({
  root: {
    height: "100%",
    overflowY: "auto"
  }
}));
interface Igroups {
  id: string;
  title: string;
  items: [];
}

interface IrelationAssets {
  groups: Igroups[];
}

function RelationAssets({ groups }: IrelationAssets): React.ReactElement {
  const [expandStatus, setExpand] = React.useState(
    groups.reduce((prev, v, index) => {
      return Object.assign(prev, {[v.title + index]: true});
    }, {})
  );

  const classes = useRelationAssetsStyle({});

  const assetIds = _.flatMapDeep(groups.map(g => g.items));
  const assets = useLoadAssetByIds(assetIds);
  const openAssetDetail = useOpenAssetDetail();

  const toggle = (key: string): void => {
    setExpand({
      ...expandStatus,
      [key]: !expandStatus[key],
    });
  };

  const listItemsGroup = groups.map((group, index) => {
    const { title, items} = group;
    
    const listItems = items.map( item => {
      if (!assets[item]) {
        return null;
      }
      const {thumbnail, name} = _.get(
        assets[item], "current", {thumbnail: "", name: ""}
      );


      return (
        <List key={item} dense>
          <ListItem alignItems="flex-start" dense 
            onClick={ (): void => openAssetDetail(item)}
          >
            <ListItemAvatar>
              <Avatar alt={name} src={thumbnail} />
            </ListItemAvatar>
            <ListItemText primary={name} />
          </ListItem>
        </List>
      );
    });

    const key = `${title}${index}`;
    return (
      <div key={key}>
        <Divider />
        <ListItem button onClick={ (): void => toggle(key)}>
          <ListItemText primary={title} />
          {expandStatus[key] ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={expandStatus[key]} timeout="auto">
          {listItems}
        </Collapse>
      </div>
    );
  });

  return (
    <List className={classes.root}>
      {listItemsGroup}
    </List>
  );
}

type ParamsType = {
  pid: string;
}
export function Product(props: RouteComponentProps<ParamsType>): React.ReactElement {
  const {match:{params: {pid}}} = props;
  const product = useGetProduct(pid);
  const isSmallScreen = useSmallScreen();
  const classes = useStyles({isSmallScreen});
  const dispatch = useDispatch();

  const openImage = (url: string, alt?: string): void => {
    dispatch(openImageDialog(url, alt));
  };

  if (!product) {
    return null;
  }

  const cover = _.get(product.cover, "current.url");

  return (
    <Box className={classes.main}>
      <Box className={classes.imageContainer}>
        <img src={cover} alt={product.name} className={classes.cover}
          onClick={(): void => openImage(cover, product.name)}
        />
      </Box>
      <Box className={classes.tabContainer}>
        <ProductTabs product={product} openImage={openImage} />
      </Box>
    </Box>
  );
}