
import { makeStyles, createStyles } from "@material-ui/styles";
import {
  DialogContent, DialogTitle,
  IconButton, ButtonGroup, Theme,
} from "@material-ui/core";
import {
  Close, RotateLeft,
  ZoomOut, ZoomIn,
} from "@material-ui/icons";

import React, { useState } from "react";

import { useCloseDialog } from "../hooks";

const useStyles = makeStyles((theme: Theme) => createStyles({
  buttonGroup: {
    boxShadow: theme.shadows[1],
    borderRadius: "60px",
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
  },
  closeButton: {
    position: "absolute",
    right: "12px",
    top: "4px"
  },
  content: {
    display: "flex",
    flexDirection: "column",
  },
  image: {
    margin: "auto",
    height: "auto",
    width: "auto",
    maxHeight: "100%",
    maxWidth: "100%"
  },
}));

interface ImageDialogData {
  data: {
    alt?: string;
    src: string;
  };
}

export default function Dialog({data}: ImageDialogData): React.ReactElement {
  const classes = useStyles({});
  const closeDialog = useCloseDialog();

  const [scale, setScale] = useState(1);
  const addScale = (s: number) => (): void => setScale((preScale) => {
    const nextScale = preScale + s;
    if ((nextScale < 0.4) || (nextScale > 3)) {
      return preScale;
    }
    return nextScale;
  });

  const {alt, src} = data;

  return (
    <>
      <DialogTitle>
        {alt}
        <IconButton onClick={closeDialog}
          className={classes.closeButton}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <img src={src} alt={alt} className={classes.image}
          style={{transform: `translate(0px) scale(${scale})`}}
        />
      </DialogContent>
      <ButtonGroup className={classes.buttonGroup}>
        <IconButton onClick={addScale(-0.5)} disabled={scale <= 0.5}>
          <ZoomOut />
        </IconButton>
        <IconButton onClick={(): void => setScale(1)} disabled={scale === 1}>
          <RotateLeft />
        </IconButton>
        <IconButton onClick={addScale(0.5)} disabled={scale >= 3} >
          <ZoomIn />
        </IconButton>
      </ButtonGroup>
    </>
  );
}