import {
  Container,
  Typography,
  makeStyles,
  Theme,
  createStyles,
  Paper,
} from '@material-ui/core';

import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { useDispatch } from 'react-redux';

import { getArticle } from './actions';
import { useArticleSelector } from './selectors';
import RichContent from '../components/richContent';

import { useTenantIdSelector } from '../selectors';

import { Iarticle } from './reducer';

function useGetActicle(aid: string): Iarticle {
  const dispatch = useDispatch();
  const tenantId = useTenantIdSelector();
  const article = useArticleSelector(aid);

  useEffect(
    () => {
      if (tenantId && aid) {
        dispatch(getArticle(tenantId, aid));
      }
    },
    [tenantId, aid]
  );
  return article;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  paperRoot: {
    minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
    width: "100%",
    backgroundColor: theme.palette.common.white,
    padding: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
  }
}));

type ParamsType = {
	aid: string;
};

export function Article(props: RouteComponentProps<ParamsType>): React.ReactElement {
  const classes = useStyles({});
  const { match: { params: { aid } }} = props;
  const article = useGetActicle(aid);
  if (article) {
    const { name } = article;
    
    return (
      <Paper className={classes.paperRoot} elevation={0}>
        <Container fixed>
          <Typography variant="h4" gutterBottom>
            {name}
          </Typography>
          <RichContent content={article.content} />
        </Container>
      </Paper>
    );
  }
  return null;
}