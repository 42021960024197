import url from "url";

import _ from "lodash";

import {
  LOAD_SHOWCASE_SUCCESS, TOGGLE_DRAWER, SET_CURRENT_CONTENT_ID,
} from "./actions";
import { Iasset } from "../folder/reducer";

export interface IshowcaseContent {
  asset?: Iasset;
  categoryId: string;
  href: string;
  id: string;
  _id: string;
  thumbnail: string;
  title: string;
  type: string;
}

export interface Ivcard {
  companyName: string;
  companyLogo: string;
  companyWebsite: string;
  name: string;
  email: string;
  mobile: string;
}
export interface Ishowcase {
  _id: string;
  content: IshowcaseContent[];
  subject: string;
  vcard: Ivcard;
  tenant: {
    _id: string;
  };
}
export interface IshowcaseState {
  drawerOpen: boolean;
  showcase: null | Ishowcase;
  currentContentId: null | string;
}
const initShowcaseState: IshowcaseState = {
  drawerOpen: false,
  showcase: null,
  currentContentId: null,
};

function parsePath(href: string): null | {type: string; _id: string} {
  const {pathname = "", hash = ""} = url.parse(href);

  const rules = [
    {type: "product", regex: /^\/products\/detail\/([^/]+)/},
    {type: "product", regex: /^\/preview\/productdetail\/([0-9a-z]+)/},
    {type: "product", regex: /^#!\/product\/([0-9a-z]+)$/},

    {type: "categories", regex: /^\/products\/([^/]+)(\/list)?$/},
    {type: "categories", regex: /^#!\/products\/([0-9a-z]+)$/},

    {type: "article", regex: /^\/cases\/detail\/([0-9a-z]+)$/},
    {type: "article", regex: /^#!\/article\/([0-9a-z]+)$/},
  ];

  return rules.reduce((acc: null | {type: string; _id: string}, {type, regex}) => {
    if (acc) {
      return acc;
    }

    let _id: string = _.get(pathname.match(regex), 1);
    if (!_id) {
      _id = _.get((hash || "").match(regex), 1);
    }

    if (_id) {
      return {type, _id};
    }

    return null;
  }, null);
}

function transformContent(content: IshowcaseContent[]): IshowcaseContent[] {
  return content.map((item) => {
    if (item.type !== "link") {
      return item;
    }

    const res = parsePath(item.href);
    if (res) {
      return Object.assign({}, item, res);
    }

    return item;
  });
}

const reducer = (state = initShowcaseState, action): IshowcaseState => {
  switch (action.type) {
  case LOAD_SHOWCASE_SUCCESS:
    return {
      ...state,
      showcase: Object.assign(
        {}, action.payload,
        {content: transformContent(action.payload.content)}
      ),
    };

  case TOGGLE_DRAWER: {
    return {
      ...state,
      drawerOpen: !state.drawerOpen,
    };
  }

  case SET_CURRENT_CONTENT_ID: {
    return {
      ...state,
      currentContentId: action.payload
    };
  }

  default:
    return state;
  }
};

export default reducer;
export { MODULE_NAME } from "./actions";
