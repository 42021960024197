import _ from "lodash";

function randomStrings(): string {
  return Array
  .from({length: 32}, () => Math.floor((Math.random() * 16)).toString(16))
  .join("");
}

enum HTTPMethodEnums {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

const jsonMimeType = "application/json";

export const TOKEN_LOCALSTORAGE_KEY = "token";
export const TOKEN_UDID_KEY = "udid";
export const UNION_ID_KEY = "unionId";

export const setToken = (token: string): void => {
  localStorage.setItem(
    TOKEN_LOCALSTORAGE_KEY,
    token,
  );
};

export const getToken = (): string => {
  const data = localStorage.getItem(TOKEN_LOCALSTORAGE_KEY);
  if (!data) {
    return null;
  }
  return data;
};

export const setUdid  = (): string => {
  const udid = randomStrings();
  localStorage.setItem(
    TOKEN_UDID_KEY,
    udid,
  );
  return udid;
};

export const getUdid = (): string => {
  const data = localStorage.getItem(TOKEN_UDID_KEY);
  if (!data) {
    return setUdid();
  }
  return data;
};

export const getUnionId = (): string => {
  return localStorage.getItem(UNION_ID_KEY);
};

function handleFetchOption(option): RequestInit {
  const method = option.method || HTTPMethodEnums.GET;
  const contentType = _.get(option, "headers.Content-Type");

  let headers = {};

  const body = _.get(option, "body");
  let registerToken;
  let token;
  if (body) {
    registerToken = _.get(JSON.parse(body), "token");
  }
  if (registerToken) {
    token = registerToken;
  } else {
    token = getToken();
  }
  if (token) {
    headers = Object.assign(headers, {Authorization: `Bearer ${token}`});
  }

  if ((method !== HTTPMethodEnums.GET) && (contentType !== "multipart/form-data")) {
    headers = Object.assign(headers, {"Content-Type": jsonMimeType});
  }

  if (contentType === "multipart/form-data") {
    delete option.headers["Content-Type"];
  }

  return Object.assign({}, option, {
    headers: Object.assign(headers, option.headers),
    method,
  });
}

type APIRes = Promise<{ message: string } | object | object[]>;
async function fetchAPI(uri: RequestInfo, option: RequestInit = {}): APIRes {
  const url = `/api/${uri}`;

  const hres = await fetch(url, option);

  const resContentType = hres.headers.get("Content-Type") || "";
  let body;
  if (resContentType.includes(jsonMimeType)) {
    body = await hres.json();
  } else {
    body = await hres.text();
  }

  if (hres.status >= 400) {
    const error =  new Error("fetch api error");
    Object.assign(error, {
      body, hres,
      sattus: hres.status,
    });
    throw error;
  }

  return body;
}

function wrapperTenantIdOption(tenantId: string): RequestInit {
  return handleFetchOption({headers: {"X-Tenant-Id": tenantId}});
}

export const fetchTenant = (domain: string): APIRes => fetchAPI(`user/tenant/${domain}`);

export enum EventActions {
  Create = "create",
  View = "view",
  ViewProfile = "view_profile",
  PageView = "pageview",
  Download = "download",
  SignOut = "sign_out",
  SignInByMobile = "signin_by_mobile",
  ShareByEmail = "by_email",
  ShareByQrcode = "by_qrcode",
  AddCollection = "add_item",
}
export enum EventCategorys {
  Asset = "asset",
  Account = "account",
  Category = "category",
  Collection = "collection",
  Collections = "collections",
  Products = "products",
  Selection = "selection",
  SignIn = "SignIn",
  Share = "share",
}
export interface Ievent {
  action: EventActions;
  category: EventCategorys;
  label: null | string;
  value: object;
  memberID: string;
  tenantID: string;
}
export const event = (data: Ievent): APIRes => {
  const body = Object.assign({
    app_version: "showcase-2019-09", // eslint-disable-line
    channel: "orbit",
    platform: "web",
    referrer: document.referrer,
    ts: + new Date(),
    ua: navigator.userAgent,
    udid: getUdid(),
    url: location.href,
    webview: _.get(window, "orbitBridge", null) ? true : false,
  }, {
    event_action: data.action, // eslint-disable-line
    event_category: data.category, // eslint-disable-line
    event_label: data.label, // eslint-disable-line
    event_value: data.value, // eslint-disable-line
    member_id: data.memberID, // eslint-disable-line
    tenant_id: data.tenantID, // eslint-disable-line
  });

  return fetchAPI(`stats/events`, {
    body: JSON.stringify(body),
    method: HTTPMethodEnums.POST,
  });
};

export const fetchFolder = (tenantId: string, id: string): APIRes => fetchAPI(
  `folders/${id}`, wrapperTenantIdOption(tenantId),
);

export const fetchAsset = (tenantId: string, id: string): APIRes => fetchAPI(
  `assets/${id}`, wrapperTenantIdOption(tenantId),
);

export const fetchAssetAll = (tenantId: string): APIRes => fetchAPI(
  "assets/all", wrapperTenantIdOption(tenantId),
);

export const fetchAssetPreview = (tenantId: string, id: string): APIRes => fetchAPI(
  `assets/${id}/preview`, wrapperTenantIdOption(tenantId),
);

export const fetchCategory = (tenantId: string, id: string): APIRes => fetchAPI(
  `catalog/categories/${id}`, wrapperTenantIdOption(tenantId),
);

export const fetchProduct = (tenantId: string, id: string): APIRes => fetchAPI(
  `catalog/products/${id}`, wrapperTenantIdOption(tenantId),
);

export const fetchArticle = (tenantId: string, id: string): APIRes => fetchAPI(
  `articles/${id}`, wrapperTenantIdOption(tenantId),
);

export const fetchTopic = (tenantId: string, id: string): APIRes => fetchAPI(
  `topics/${id}`, wrapperTenantIdOption(tenantId),
);

export const fetchShowcase = (showcaseId: string): APIRes => fetchAPI(
  `share/showcase/${showcaseId}`,
);

export const genQrcode = (uri: string): string => {
  return `/api/assets/qrcode?type=png&size=1000&download=true&url=${encodeURIComponent(uri)}`;
};

export const fetchJsSdkSignature = (url: string): APIRes => {
  const body = {url:url};
  return fetchAPI(`portal/wechat/jsSdkSignature`, {
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json"
    },
    method: HTTPMethodEnums.POST,
  });
};

export const showcaseEvent = (sid, type, data): APIRes => {
  const {
    context: dataContext,
    properties
  } = data || {context: {}, properties: {}};

  const context = _.extend({}, dataContext, {
    "showcase_id": sid,
    "union_id": getUnionId(),
    udid: getUdid(),
    page: {
      referrer: document.referrer,
      ua: window.navigator.userAgent,
    },
  });

  const body = {
    context,
    properties,
    event: type,
    ts: new Date().getTime(),
  };
  return fetchAPI(`share/showcase/${sid}`, {
    method: HTTPMethodEnums.POST,
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json"
    },
  });
};
