
export const MODULE_NAME = "folder";
export const LOAD_ASSET_SUCCESS = "folder/load_asset_success";
export const LOAD_ASSET_PREVIEW_SUCCESS = "folder/load_asset_preview_success";
export const LOAD_ASSET_ALL_SUCCESS = "folder/load_asset_all_success";
export const LOAD_FOLDER_SUCCESS = "folder/load_folder_success";
export const ASSET_WATCH_TIME = "folder/asset/watch_time";

export function loadAsset(tenantId: string, aid: string) {
  return (dispatch, getState, {api}): Promise<object> => {
    return api.fetchAsset(tenantId, aid)
    .then((res) => {
      dispatch({
        type: LOAD_ASSET_SUCCESS,
        payload: res
      });
    });
  };
}

export function loadAssetPreview(tenantId: string, aid: string) {
  return (dispatch, getstate, {api}): void => {
    api.fetchAssetPreview(tenantId, aid)
    .then((res) => {
      dispatch({
        type: LOAD_ASSET_PREVIEW_SUCCESS,
        payload: res,
        id: aid,
      });
    });
  };
}

export function countAssetWatchTime(aid: string, duration: number, page?: number) {
  return (dispatch): void => {
    dispatch({
      type: ASSET_WATCH_TIME,
      payload: duration,
      id: aid,
      page,
    });
  };
}
