
export const MODULE_NAME = "article";
export const LOAD_ARTICLE_REQUEST = "article/load/request";
export const LOAD_ARTICLE_SUCCESS = "article/load/success";
export const LOAD_ARTICLE_FAILURE = "article/load/failure";
export const LOAD_TOPIC_REQUEST = "topic/load/request";
export const LOAD_TOPIC_SUCCESS = "topic/load/success";
export const LOAD_TOPIC_FAILURE = "topic/load/failure";

export function getArticle(tenantId: string, cid: string) {
  return (dispath, getState, {api}): void => {
    api.fetchArticle(tenantId, cid)
    .then((res) => {
      dispath({
        type: LOAD_ARTICLE_SUCCESS,
        payload: res,
      });
    });
  };
}

export function getTopic(tenantId: string, tid: string) {
  return (dispath, getState, {api}): void => {
    api.fetchTopic(tenantId, tid)
    .then((res) => {
      dispath({
        type: LOAD_TOPIC_SUCCESS,
        payload: res,
      });
    });
  };
}