import { Dispatch } from "redux";

export const MODULE_NAME = "main";
export const SET_TENANTID = "main/set_tenantId";
export const SET_CURRENT_CATEGORY_ID = "main/set_current_categoryId";

export const CLOSE_DIALOG = "main/dialog/close_dialog";
export const OPEN_IMAGE_DIALOG = "main/dialog/open_image";
export const OPEN_ASSET_DETAIL_DIALOG = "main/dialog/open_asset_detail";

export enum DIALOGS {
  INIT,
  IMAGE_DIALOG,
  ASSET_DETAIL,
}

export function setTenantId(tid: string) {
  return (dispath: Dispatch): void => {
    dispath({
      type: SET_TENANTID,
      payload: tid,
    });
  };
}

export function setCurrentCategoryId(cid: string) {
  return (dispath: Dispatch): void => {
    dispath({
      type: SET_CURRENT_CATEGORY_ID,
      payload: cid,
    });
  };
}

export function closeDialog() {
  return (dispatch: Dispatch): void => {
    dispatch({type: CLOSE_DIALOG});
  };
}

export function openImageDialog(src: string, alt?: string) {
  return (dispath: Dispatch): void => {
    dispath({
      dialog: DIALOGS.IMAGE_DIALOG,
      fullScreen: true,
      type: OPEN_IMAGE_DIALOG,
      maxWidth: false,
      payload: {alt, src},
    });
  };
}

export function openAssetDetailDialog(payload) {
  return (dispatch): void => {
    dispatch({
      dialog: DIALOGS.ASSET_DETAIL,
      fullScreen: true,
      type: OPEN_ASSET_DETAIL_DIALOG,
      maxWidth: false,
      payload,
    });
  };
}
