import {
  DialogContent, DialogContentText,
} from "@material-ui/core";

import React, { ReactElement } from "react";

import { useLoadAsset } from "./hooks";
// import { EventActions, EventCategorys } from "./api";
import PDFView from "./pdfView";
import { VideoDialog } from "../components/videoView";

import ImageDialog from "../components/imageDialog";
import { isVideo, isImage, isPDF } from "../utils/index";
import { useSmallScreen } from "../selectors";


function UNSupportFileView(): ReactElement {
  return (
    <DialogContent>
      <DialogContentText color="error" align="center">
        不支持预览的文件格式，请下载后在本地预览
      </DialogContentText>
    </DialogContent>
  );
}

export function unsupportFileType(mimeType): boolean {
  return [isVideo, isPDF, isImage].every((f) => !f(mimeType));
}

export default function AssetDetial({data: id}: {data: string}): ReactElement {
  const asset = useLoadAsset(id);
  const isSmallScreen = useSmallScreen();

  // useDurationEvent(asset && {
  //   action: EventActions.View,
  //   category: EventCategorys.Asset,
  //   label: asset._id,
  //   value: {
  //     name: _.get(asset, "current.name"),
  //   },
  // });

  if (!asset) {
    return null;
  }

  const {current: {url, name, thumbnail, mimeType}} = asset;

  if (isVideo(mimeType)) {
    return (
      <VideoDialog src={url} poster={thumbnail} name={name} />
    );
  }

  if (isPDF(mimeType)) {
    return (
      <PDFView asset={asset} file={url}
        isSmallScreen={isSmallScreen} thumbnail={thumbnail}
      />
    );
  }

  if (isImage(mimeType)) {
    return (
      <ImageDialog data={{src: url, alt: name}} />
    );
  }

  return (<UNSupportFileView />);
}
