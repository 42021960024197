import {
  Card,
  Divider,
  Theme,
  CardActionArea,
  CardMedia,
  ListItem,
  List,
  CardContent,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import _ from "lodash";
import React, {
  useEffect, useRef,
} from "react";
import { RouteComponentProps } from "react-router";

import { useShowcaseSelector, useCurrentContentIdSelector } from "./selectors";
import { useSetCurrentContentId } from "./hooks";

import { getFileIcon } from "../utils/fileTypeIcon";

const ListItemHeight = 210;
const useStyles = makeStyles((theme: Theme) => ({
  logo: Object.assign({}, theme.mixins.toolbar, {
    display: "flex",
    padding: theme.spacing(1),
    height: theme.mixins.toolbar.minHeight,
  }),
  logoImage: {
    maxHeight: "100%",
    maxWidth: "100%",
    margin: "auto",
  },
  actionArea: {
    height: "100%"
  },
  cardRoot: {
    width: "100%",
    height: "100%"
  },
  cardMedia: {
    backgroundSize: "contain",
    height: "60%",
  },
  wraper: {
    overflowY: "auto"
  },
  listItem: {
    height: `${ListItemHeight}px`
  }
}));

export default function Drawer({history, match}: RouteComponentProps): React.ReactElement {
  const classes = useStyles({});
  const showcase = useShowcaseSelector();
  const ref = useRef(null);
  const setCurrentContentId = useSetCurrentContentId();
  const currentContentId = useCurrentContentIdSelector();

  useEffect(() => {
    if (ref && showcase) {
      const index = showcase
      .content.findIndex((c) => c.id === currentContentId);
      const offsetY = 64 + index * ListItemHeight;
      ref.current.scrollTo({
        top: offsetY - ((ref.current.clientHeight - ListItemHeight) / 2),
        behavior: "smooth"
      });
    }
  }, [currentContentId]);

  if (!showcase) {
    return null;
  }

  const handleClick = (id) => (): void => {
    setCurrentContentId(id);
    if (!match.isExact) {
      history.push(`/${showcase._id}`);
    }
  };

  const companyName = showcase.vcard.companyName;
  const companyLogo = showcase.vcard.companyLogo;
  const contents = showcase.content;
  return (
    <div ref={ref} className={classes.wraper}>
      <div className={classes.logo} >
        <img src={companyLogo} alt={companyName} className={classes.logoImage} />
      </div>
      <Divider />
      <List>
        {contents.map((c) => {
          let thumbnail = _.get(c, "thumbnail");
          if (!thumbnail) {
            thumbnail = _.get(c, "asset.current.thumbnail");
          }
          if (!thumbnail) {
            thumbnail = getFileIcon(_.get(c, "asset.current.fileName", ".link"));
          }

          let title = _.get(c, "title");
          if (!title) {
            title = _.get(c, "asset.current.name");
          }

          return (
            <ListItem key={c.id} divider className={classes.listItem} >
              <Card elevation={currentContentId === c.id ? 2 : 0}
                className={classes.cardRoot}
              >
                <CardActionArea onClick={handleClick(c.id)}
                  className={classes.actionArea}
                >
                  <CardMedia image={thumbnail}
                    className={classes.cardMedia}
                  />
                  <CardContent>
                    <Typography align="center" variant="subtitle2">{title}</Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </ListItem>
          );
        })}
      </List>
    </div>
  );
}
