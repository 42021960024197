import {
  AppBar,
  Card, CardContent,
  Drawer,
  makeStyles,
  Hidden,
  Toolbar,
  useScrollTrigger,
  Typography,
  Popover,
  IconButton,
  createStyles,
  CardMedia,
} from "@material-ui/core";
import { AccountCircleRounded, MenuRounded } from "@material-ui/icons";

import React, { Suspense } from "react";
import {Route, RouteComponentProps}  from "react-router-dom";
import _ from "lodash";

import {
  smallScreenBreakProint,
  afterSmallScreenBreakProint,
  useSmallScreen,
} from "../selectors";

import {
  useGetShowcase,
  useToggleDrawer
} from "./hooks";
import Contents from "./contents";
import ShowcaseDrawer from "./drawer";
import { Article } from "../topic";
import { Category, Product } from "../category";
import {
  useShowcaseVcardSelector,
  useDrawerSelector
} from "./selectors";
import { Ivcard } from "./reducer";

interface IuserStyle {
  drawerWidth?: number;
}
const useStyles = makeStyles((theme) => {
  const nextSmallScreenBreakPorint = theme.breakpoints.keys[
  _.indexOf(theme.breakpoints.keys, smallScreenBreakProint) + 1
  ];

  return createStyles({
    appBar: ({ drawerWidth }: IuserStyle) => ({
      marginLeft: drawerWidth,
      [theme.breakpoints.up(nextSmallScreenBreakPorint)]: {
        width: `calc(100% - ${drawerWidth}px)`,
      }
    }),
    content: {
      flexGrow: 1,
      minHeight: "100vh",
    },
    drawer: ({ drawerWidth }: IuserStyle) => ({
      [theme.breakpoints.up(nextSmallScreenBreakPorint)]: {
        width: drawerWidth,
        flexShrink: 0,
      }
    }),
    drawerPaper: ({ drawerWidth }: IuserStyle) => ({
      width: `${drawerWidth}px`,
      overflowY: "hidden"
    }),
    root: {
      backgroundColor: "#edf0f2",
      display: "flex",
    },
    card: {
      display: 'flex',
    },
    cardMedia: {
      height: "auto",
      objectFit: "contain",
      maxWidth: "120px",
      maxHeight: "240px",
      padding: theme.spacing(1),
      [theme.breakpoints.up(smallScreenBreakProint)]: {
        maxWidth: "240px",
        maxHeight: "240px",
        padding: theme.spacing(4),
      }
    },
  });
});

function ShowcaseAppBar(): React.ReactElement {
  const toggleDrawer = useToggleDrawer();
  const isSmallScreen = useSmallScreen();

  return (
    <Toolbar>
      {isSmallScreen && <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={toggleDrawer}
      >
        <MenuRounded />
      </IconButton>}
      <Typography component="div" color="secondary" style={{margin: "auto"}}>
        <VcardPopover />
      </Typography>
    </Toolbar>
  );
}
interface IvcardType {
  vcard: Ivcard;
}

function Vcard(props: IvcardType): React.ReactElement {

  const {
    companyLogo, companyName, companyWebsite,
    name,
    mobile,
    email
  } = props.vcard;

  const classes = useStyles({});

  return (
    <Card className={classes.card}>
      <CardMedia component="img" src={companyLogo} className={classes.cardMedia}/>
      <CardContent>
        <Typography variant="h5" gutterBottom>{name}</Typography>
        <Typography variant="subtitle2" paragraph color="textSecondary">
          {companyName}
        </Typography>
        <Typography component="div" variant="body2" gutterBottom>
          <a href={companyWebsite}>{companyWebsite}</a>
        </Typography>
        <Typography component="div" variant="body2" gutterBottom>
          <a href={`tel:${mobile}`}>{mobile}</a>
        </Typography>
        <Typography component="div" variant="body2" >
          <a href={`mailto:${email}`}>{email}</a>
        </Typography>
      </CardContent>
    </Card>
  );
}

function VcardPopover(): React.ReactElement {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const vcard = useShowcaseVcardSelector();

  if (!vcard) {
    return null;
  }
  const { name } = vcard;

  return (
    <>
      <Typography color="secondary" align="center"
        component="div" style={{display: "flex"}}
        onClick={handleClick}>
        <AccountCircleRounded/>
        <u style={{display: "inline-block", margin: "0 4px"}}> { name } </u> 向您分享了以下内容
      </Typography>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Vcard vcard={vcard} />
      </Popover>
    </>
  );
}
function ElevationScroll({ children }): React.ReactElement {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    target: window,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

type ParamsType = {
  showcaseId: string;
};
function Showcase(props: RouteComponentProps<ParamsType>): React.ReactElement {
  const {match:{params: {showcaseId}}} = props;
  const classes = useStyles({drawerWidth: 240});
  useGetShowcase(showcaseId);
  const drawerOpen = useDrawerSelector();
  const toggleDrawer = useToggleDrawer();

  return (
    <div className={classes.root} >
      <ElevationScroll>
        <AppBar color="primary" className={classes.appBar}>
          <ShowcaseAppBar />
        </AppBar>
      </ElevationScroll>
      <nav className={classes.drawer}>
        <Hidden {...{[`${afterSmallScreenBreakProint}Up`]: true}} implementation="css">
          <Drawer variant="temporary" classes={{paper: classes.drawerPaper}}
            open={drawerOpen} anchor="left" ModalProps={{keepMounted: true}}
            onBackdropClick={(e): void => {e.stopPropagation(); toggleDrawer();}}
            onClick={toggleDrawer}
          >
            <ShowcaseDrawer {...props} />
          </Drawer>
        </Hidden>
        <Hidden {...{[`${smallScreenBreakProint}Down`]: true}} implementation="css">
          <Drawer open variant="permanent" classes={{paper: classes.drawerPaper}}>
            <ShowcaseDrawer {...props} />
          </Drawer>
        </Hidden>
      </nav>
      <Suspense fallback="loading...">
        <main className={classes.content} >
          <Toolbar />
          <Route exact path="/:showcaseId/article/:aid" component={Article} />
          <Route exact path="/:showcaseId/categories/:cid" component={Category} />
          <Route exact path="/:showcaseId/product/:pid" component={Product} />
          <Route exact path="/:showcaseId" component={Contents} />
        </main>
      </Suspense>
    </div>
  );
}

export default Showcase;