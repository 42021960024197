import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import detector from "i18next-browser-languagedetector";
import backend from "i18next-xhr-backend";

// install();
i18n
.use(initReactI18next)
.use(detector)
.use(backend)
.init({
  whitelist: ["zh-CN"],
  fallbackLng: "zh-CN",
  load: "currentOnly",
  keySeparator: ".",
  // parseMissingKeyHandler: key => key,
  interpolation: {
    escapeValue: false
  },
  backend: {
    loadPath: "/locales/{{lng}}/{{ns}}.json"
  }
});

export default i18n;
