import { useEffect } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";

import { useTenantIdSelector } from "../selectors";

import { Iasset, IassetPreivew } from "./reducer";
import {
  useAssetSelector,
  useAssetPreviewSelector,
  useAssetByIdsSelector
} from "./selectors";
import {
  countAssetWatchTime,
  loadAsset, loadAssetPreview,
} from "./actions";

export function useLoadAsset(aid: string): null | Iasset {
  const tenantId = useTenantIdSelector();
  const dispatch = useDispatch();
  const asset = useAssetSelector(aid);

  useEffect(() => {
    if (tenantId && !asset) {
      dispatch(loadAsset(tenantId, aid));
    }
  }, [tenantId, aid]);

  return asset;
}

export function useLoadAssetPreview(aid: string): null | IassetPreivew {
  const tenantId = useTenantIdSelector();
  const dispatch = useDispatch();
  const assetPreview = useAssetPreviewSelector(aid);

  useEffect(() => {
    if (tenantId && !assetPreview) {
      dispatch(loadAssetPreview(tenantId, aid));
    }
  }, [tenantId, aid]);

  return assetPreview;
}

export function useLoadAssetByIds(ids: string[]): {[k: string]: Iasset} {
  const tenantId = useTenantIdSelector();
  const dispatch = useDispatch();
  const assets = useAssetByIdsSelector(ids);
  const compactAssets = _.compact(assets);

  useEffect(() => {
    if (tenantId && compactAssets.length !== ids.length) {
      ids.reduce((acc, aid) => {
        return acc.then(() => dispatch(loadAsset(tenantId, aid)));
      }, Promise.resolve());
    }
  }, []);

  return compactAssets.reduce((acc, a) => {
    return Object.assign(acc, {[a._id]:a});
  }, {});
}

export function useCountAssetWatchTime(aid: string): (duration: number, page?: number) => void {
  const dispatch = useDispatch();

  return (duration: number, page?: number): void => {
    dispatch(countAssetWatchTime(aid, duration, page));
  };
}