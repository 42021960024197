
import _ from "lodash";

import { FileTypeIconMap } from "./fileTypeIConMap";

const defaultPath = "/images/fileType";

export function getExtname(fileName: string): string {
  return fileName.split(".").reverse()[0] || "";
}

export function getFileType(fileName: string): string {
  const extname = getExtname(fileName).toLowerCase();
  return _.findKey(FileTypeIconMap, ({extensions = []}) => {
    return extensions.includes(extname);
  }) || "genericfile";
}

export function getFileIcon(fileName: string): string {
  const fileType = getFileType(fileName);

  return `${defaultPath}/${fileType}.svg`;
}
