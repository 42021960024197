import { LOAD_CATEGORY_SUCCESS, LOAD_PRODUCT_SUCCESS } from "./actions";

interface Iasset {
  _id: string;
  current: {
    url: string;
    thumbnail: string;
  };
}

interface IproductAddition {
  content: string;
  title: string;
  groups: [];
  type: "content" | "assets";
}
export interface Iproduct {
  _id: string;
  addition: IproductAddition[];
  description: string;
  cover: Iasset;
  name: string;
  type: string;
}

export interface Icategory {
  _id: string;
  cover: Iasset;
  description: string;
  name: string;
  type: string;
  categories: string[];
  products: string[];
  subCategories: Icategory[];
  subProducts: Iproduct[];
}

export interface IcategoryState {
  categories: {[k: string]: Icategory};
  products: {[k: string]: Iproduct};
}
const initState: IcategoryState = {
  categories: {},
  products: {},
};

export default function reduder(state = initState, action): IcategoryState {

  switch (action.type) {

  case LOAD_CATEGORY_SUCCESS: {
    const subCategories = action.payload.categories;
    const subProducts = action.payload.products;

    return {
      ...state,
      products: {
        ...state.products,
        ...subProducts.reduce((acc, p) => {
          return Object.assign(acc, {[p._id]: p});
        }, {}),
      },
      categories: {
        ...state.categories,
        ...{[action.payload._id]: Object.assign({}, action.payload, {
          products: subProducts.map(({_id}) => _id),
          categories: subCategories.map(({_id}) => _id),
          load: true,
        })},
        ...subCategories.reduce((acc, c) => Object.assign(acc, {
          [c._id]: Object.assign(
            { load: false, subProducts: [], subCategories: [] },
            state.categories[c._id],
            c,
            { products: c.products.map(({_id}) => _id) },
          ),
        }), {}),
      },
    };
  }

  case LOAD_PRODUCT_SUCCESS: {
    return {
      ...state,
      products: {
        ...state.products,
        [action.payload._id]: action.payload
      }
    };
  }

  default: {
    return state;
  }
  }
}

export { MODULE_NAME } from "./actions";