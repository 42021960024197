
import React, {
  ReactElement, useState, useEffect
} from "react";
import differenceInSeconds from "date-fns/differenceInSeconds";
import { useInView } from "react-intersection-observer";

import {
  makeStyles,
  createStyles, Card, CardActionArea, CardMedia, CardContent,
  Typography,
  Theme,
} from "@material-ui/core";

import { Iasset } from "./reducer";
import { useLoadAssetPreview, useCountAssetWatchTime } from "./hooks";

import { VideoPlayer } from "../components/videoView";
import { isImage, isPDF, isVideo } from "../utils";
import { useOpenAssetDetail } from "../hooks";
import { getFileIcon } from "../utils/fileTypeIcon";
import { smallScreenBreakProint } from "../selectors";

const useStyles = makeStyles(() => createStyles({
  root: {
    width: "100%",
  },
  image: {
    width: "100%",
  },
  pdfPreviewRoot: {
    width: "100%"
  },
}));

interface IiamgePreview {
  classes: ReturnType<typeof useStyles>;
  src: string;
  alt: string;
  onClick: () => void;
  countWatchTime: ReturnType<typeof useCountAssetWatchTime>;
}
function ImagePreview (props: IiamgePreview): ReactElement {
  const {
    classes, src, alt,
    onClick, countWatchTime,
  } = props;
  
  const [startTime, setStartTime] = useState(0);
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      setStartTime(+ new Date());
    } else {
      if (startTime) {
        const duration = differenceInSeconds(new Date(), startTime);
        if (duration) {
          countWatchTime(duration);
          setStartTime(0);
        }
      }
    }
  }, [inView]);

  return (
    <div className={classes.root} ref={ref} >
      <img src={src} alt={alt} className={classes.image}
        onClick={onClick}
      />
    </div>
  );
}

interface IpdfPreview {
  classes: ReturnType<typeof useStyles>;
  loading: () => ReactElement;
  openAssetDetail: (id: string) => void;
  asset: Iasset;
  countWatchTime: ReturnType<typeof useCountAssetWatchTime>;
  page?: number;
}
function PdfPreview(props: IpdfPreview): ReactElement {
  const {
    asset, classes, loading,
    openAssetDetail, countWatchTime,
    page
  } = props;
  const {_id, current: {name}} = asset;
  const preview = useLoadAssetPreview(_id);

  if (!preview) {
    return loading();
  }


  const pages = page ? [preview[page - 1]] : preview;
  return (
    <div className={classes.pdfPreviewRoot} >
      {pages.map(({large}, index) => (
        <ImagePreview src={large} alt={`${name}_index`} key={index}
          classes={classes}
          onClick={(): void => openAssetDetail(_id)}
          countWatchTime={(duration): void => countWatchTime(duration, index + 1)}
        />
      ))}
    </div>
  );
}

const useunSupportFileCardStyles = makeStyles((theme: Theme) => createStyles({
  cardRoot: {
    width: "100%",
    minHeight: "120px",
    [theme.breakpoints.up(smallScreenBreakProint)]: {
      minHeight: "240px"
    }
  },
  cardActionArea: {
    display: "flex",
    height: "100%",
    justifyContent: "stretch"
  },
  cardMedia: {
    background: "steelblue",
    height: "100%",
    width: "auto",
    objectFit: "contain",
    padding: theme.spacing(2),
    maxHeight: "120px",
    [theme.breakpoints.up(smallScreenBreakProint)]: {
      padding: theme.spacing(5),
      maxHeight: "240px",
      minWidth: "240px"
    }
  }
}));

interface IunSupportFileCard {
  media: string;
  content: string;
  onClick: () => void;
}
function UnSupportFileCard(props: IunSupportFileCard): ReactElement {
  const {media, content, onClick} = props;
  const classes = useunSupportFileCardStyles({});

  return (
    <Card elevation={0} className={classes.cardRoot} raised>
      <CardActionArea className={classes.cardActionArea}
        onClick={onClick}
      >
        <CardMedia component="img" src={media}
          className={classes.cardMedia}
        />
        <CardContent>
          <Typography align="left" variant="h6">
            {content}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

interface IassetPreviewProps {
  classes?: Partial<ReturnType<typeof useStyles>>;
  id: string;
  title: string;
  thumbnail: string;
  asset?: Iasset;
  page?: string;
}
export function AssetPreview (props: IassetPreviewProps): ReactElement {
  const {title, asset, page} = props;
  if (!asset) {
    throw new Error("invalid data");
  }

  const classes = Object.assign(useStyles({}), props.classes);
  const openAssetDetail = useOpenAssetDetail();
  const countWatchTime = useCountAssetWatchTime(asset._id);

  let thumbnail = props.thumbnail;

  const Loading = (): ReactElement => {
    return (
      <div className={classes.root}>
        <img src={thumbnail} alt={title} className={classes.image} />
      </div>
    );
  };

  const {
    _id,
    current: { fileName, name, url, mimeType, thumbnail: assetThumb }
  } = asset;

  thumbnail = thumbnail || assetThumb;
  if (!thumbnail) {
    thumbnail = getFileIcon(fileName);
  }

  if (isImage(mimeType)) {
    return <ImagePreview src={url} alt={title || asset.current.name} 
      classes={classes}
      onClick={(): void => openAssetDetail(_id)}
      countWatchTime={countWatchTime}
    />;
  }

  if (isVideo(mimeType)) {
    return (
      <VideoPlayer src={url} poster={thumbnail}
        countWatchTime={(startTime, endTime): void => {
          countWatchTime(endTime - startTime);
        }}
      />
    );
  }

  if (isPDF(mimeType)) {
    return <PdfPreview asset={asset}
      page={parseInt(page)}
      classes={classes}
      loading={Loading}
      openAssetDetail={openAssetDetail}
      countWatchTime={countWatchTime}
    />;
  }


  const downloadLink = `${url}?attname=${name}.${fileName.split(".")[1]}`;
  return (
    <UnSupportFileCard content={name} media={thumbnail}
      onClick={(): void => { window.open(downloadLink); }}
    />
  );
}
