import _ from "lodash";
import { useEffect, useState, useRef } from "react";

type PerRowItemPercentage = {
  containerRef: React.MutableRefObject<HTMLDivElement>;
  percentage: string;
}
export function usePerRowItemPercentageSelector(minWidth = 256): PerRowItemPercentage {
  const containerRef = useRef(null);

  const calcPercentage = (): string => {
    const windowWidth = window.innerWidth;
    const width = _.get(containerRef, 'current.clientWidth', windowWidth);
    const currentNumber = Math.floor(width / minWidth);
    return `${100 / currentNumber}%`;
  };

  const [percentage, setPercentage] = useState(calcPercentage());
  const handleResize = _.throttle(() => setPercentage(calcPercentage()), 250);
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return (): void => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // container did mount
    handleResize();
  }, [containerRef]);

  return {containerRef, percentage};
}

type WindowSize = {
  height: number;
  width: number;
}
export function useWindowSize(): WindowSize {
  const getWindowSize = (): WindowSize => ({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const [windowSize, setWindowSize] = useState(getWindowSize);

  const handleResize = _.throttle(() => {
    return setWindowSize(getWindowSize);
  }, 250);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return (): void => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

type useWindowEventListener = <K extends keyof WindowEventMap>
  (
    type: K,
    listener: (this: Window, ev: WindowEventMap[K]) => void,
    options?: boolean | AddEventListenerOptions,
  ) => void;
export const useWindowEventListener: useWindowEventListener = (eventType, listener) => {
  useEffect(() => {
    window.addEventListener(eventType, listener);

    return (): void => window.addEventListener(eventType, listener);
  }, []);
};
