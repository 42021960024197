import { Theme, useMediaQuery } from "@material-ui/core";
import useTheme from "@material-ui/styles/useTheme";

import { useSelector } from "react-redux";

import { MODULE_NAME } from "./actions";
import { ImainState, Idialog } from "./reducer";

export const smallScreenBreakProint = "sm";
export const afterSmallScreenBreakProint = "md";
export function useSmallScreen(): boolean {
  const theme: Theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(smallScreenBreakProint));
}
interface Istate {
  main: ImainState;
}
export function useTenantIdSelector (): null | string {
  return useSelector((state: Istate) => {
    return state[MODULE_NAME].tenantId;
  });
}

export function useCurrentCategoryId(): null | string {
  return useSelector((state: Istate) => state[MODULE_NAME].currentCategoryId);
}

export function useDialogSelector(): Idialog {
  return useSelector((state: Istate) => state[MODULE_NAME].dialog);
}