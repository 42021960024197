import _ from "lodash";
import React, { useEffect, ReactElement } from "react";
import { RouteComponentProps } from "react-router";
import { useDispatch } from "react-redux";

import { getCategory } from "./actions";
import { useCategorySelector } from "./selectors";

import {
  useTenantIdSelector,
  useSmallScreen,
  useCurrentCategoryId,
} from "../selectors";
import { setCurrentCategoryId } from "../actions";
import {
  Folder,
  Empty,
  IFolderItem,
} from "../components/folders";
import { Icategory, Iproduct } from "./reducer";

function useGetCategory(cid: string): Icategory {
  const dispatch = useDispatch();
  const tenantId = useTenantIdSelector();
  const category = useCategorySelector(cid);

  useEffect(() => {
    if (tenantId && cid) {
      dispatch(getCategory(tenantId, cid));
    }
  }, [tenantId, cid]);

  return category;
}

type ParamsType = {
  cid: string;
}

function useJumpToCategory(): (string) => void {
  const dispatch = useDispatch();
  type action = (Dispatch) => void;
  return (cid: string): action => dispatch(setCurrentCategoryId(cid));
}

interface IcategoryItem {
  Item: IFolderItem;
  category: Icategory;
  operators: {
    jumpToCategory: (cid: string) => void;
  };
}
function CategoryItem({Item, category, operators}: IcategoryItem): React.ReactElement {
  const cover = _.get(category.cover, 'current.url');

  return <Item
    key={category._id}
    data={{
      _id: category._id, cover, primaryText: category.name,
      secondaryText: "", type: "folder",
    }}
    onClick={(): void => operators.jumpToCategory(category._id)}
    actions={[]}
  />;
}

interface IproductItem {
  Item: IFolderItem;
  product: Iproduct;
  operators: {
    jumpToProduct: (pid: string) => void;
  };
}
function ProductItem({Item, product, operators}: IproductItem): React.ReactElement {
  const cover = _.get(product.cover, "current.url");
  return <Item
    key={product._id}
    data={{
      _id: product._id, cover, primaryText: product.name,
      secondaryText: "", type: "item",
    }}
    onClick={(): void => operators.jumpToProduct(product._id)}
    actions={[]}
  />;
}

function useSyncCurrentCid(props: RouteComponentProps<ParamsType>, basePath: string): void {
  const {match:{params: {cid}}, history} = props;
  const currentCid = useCurrentCategoryId();

  useEffect(() => {
    if (cid !== currentCid) {
      setCurrentCategoryId(cid);
    }
  }, [cid]);
  useEffect(() => {
    if (currentCid && (currentCid !== cid)) {
      history.push(`${basePath}/categories/${currentCid}`);
    }
  }, [currentCid]);
}

export function Category(props: RouteComponentProps<ParamsType>): React.ReactElement {
  const {match:{params: {cid}}, history, location} = props;

  const category = useGetCategory(cid);
  const isSmallScrren = useSmallScreen();

  const basePath = location.pathname.split("/categories")[0];
  useSyncCurrentCid(props, basePath);

  const jumpToCategory = useJumpToCategory();
  const jumpToProduct = (pid): void => {
    history.push(`${basePath}/product/${pid}`);
  };

  if (!category) {
    return <Empty />;
  }

  const { subCategories, subProducts } = category;
  const categoriesFactory = (Item: IFolderItem): React.ReactElement[] => {
    return subCategories.map(sc => CategoryItem({
      Item: Item, category: sc, operators: {jumpToCategory}
    }));
  };
  const productsFactory = (Item: IFolderItem): ReactElement[] => {
    return subProducts.map(sp => ProductItem({
      Item:Item, product: sp, operators: {jumpToProduct}
    }));
  };

  return (
    <Folder isSmallScreen={isSmallScrren}
      foldersFactory={categoriesFactory}
      itemsFactory={productsFactory}
    />
  );
}
