import { useSelector } from "react-redux";

import { MODULE_NAME } from "./actions";
import { IcategoryState, Icategory, Iproduct} from "./reducer";

interface Istate {
  [MODULE_NAME]: IcategoryState;
}

/**
 * @TODO: 重复刷新问题待优化
 */
export function useCategorySelector(cid: string): Icategory {
  return useSelector((state: Istate) => {
    const category = state[MODULE_NAME].categories[cid];
    if (!category) {
      return category;
    }

    const subCategories = category.categories.map(scid => {
      return state[MODULE_NAME].categories[scid];
    }).filter(i => i);
    const subProducts = category.products.map(spid => {
      return state[MODULE_NAME].products[spid];
    }).filter(i => i);

    return Object.assign(
      {},
      category,
      { subCategories, subProducts, }
    );
  });
}

export function useProductSelector(pid: string): Iproduct {
  return useSelector((state: Istate) => {
    return state[MODULE_NAME].products[pid];
  });
}
