import { LOAD_ARTICLE_SUCCESS, LOAD_TOPIC_SUCCESS } from "./actions";

interface Iasset {
    _id: string;
    current: {
      url: string;
      thumbnail: string;
    };
  }

export interface Iarticle {
  _id: string;
  cover: Iasset;
  name: string;
  type: string;
  content: string;
  updatedAt: string;
}
export interface Itopic {
  _id: string;
  name: string;
  type: string;
  topics: [];
  articles: [];
}


export interface IarticleState {
    articles: {[k: string]: Iarticle};
    topics: {[k: string]: Itopic};
  }

const initState: IarticleState = {
  articles: {},
  topics: {},
};

export default function reduder(state = initState, action): IarticleState {

  switch (action.type) {

  case LOAD_ARTICLE_SUCCESS: {
    return {
      ...state,
      articles: {
        ...state.articles,
        [action.payload._id]: action.payload
      }
    };
  }

  case LOAD_TOPIC_SUCCESS: {
    return {
      ...state,
      topics: {
        ...state.topics,
        [action.payload._id]: action.payload
      }
    };
  }

  default: {
    return state;
  }
  }
}

export { MODULE_NAME } from "./actions";