import _ from "lodash";

import {
  LOAD_ASSET_PREVIEW_SUCCESS,
  LOAD_FOLDER_SUCCESS,
  LOAD_ASSET_SUCCESS,
  LOAD_ASSET_ALL_SUCCESS,
  ASSET_WATCH_TIME,
} from "./actions";
export { MODULE_NAME } from "./actions";

export interface Iasset {
  _id: string;
  current: {
    fileName: string;
    name: string;
    url: string;
    thumbnail: string;
    mimeType: string;
    size: number;
    info: {page_num: number};
  };
  createdAt: string;
  updatedAt: string;
}
export type IassetPreivew = Array<{
  small: string;
  large: string;
}>;
export interface IassetStats {
  totalWatchTime: number;
  watchTime: number;
  currentPage?: number;
  pages? : {
    [k: number]: {
      totalWatchTime: number;
      watchTime: number;
    };
  };
}

export interface Ifolder {
  _id: string;
  load: boolean;
  assets: string[];
  folders: string[];
  name: string;
  subAssets: Iasset[];
  subFolders: Ifolder[];
  createdAt: string;
  updatedAt: string;
}

export interface IfolerState {
  assetPreviews: {[k: string]: IassetPreivew};
  assets: {[k: string]: Iasset};
  folders: {[k: string]: Ifolder};
  assetStats: {[k: string]: IassetStats};
}
const initFolderState: IfolerState = {
  assetPreviews: {},
  assets: {},
  folders: {},
  assetStats: {},
};

export default function reducer(state = initFolderState, action): IfolerState {
  switch (action.type) {

  case LOAD_FOLDER_SUCCESS: {
    const subFolders = action.payload.folders;
    const subAssets = action.payload.assets;

    return {
      ...state,
      assets: {
        ...state.assets,
        ...subAssets.reduce((acc, a) => {
          return Object.assign(acc, {[a._id]: a});
        }, {}),
      },
      folders: {
        ...state.folders,
        ...{[action.payload._id]: Object.assign({}, action.payload, {
          assets: subAssets.map(({_id}) => _id),
          folders: subFolders.map(({_id}) => _id),
          load: true,
        })},
        ...subFolders.reduce((acc, f) => Object.assign(acc, {
          [f._id]: Object.assign(
            { load: false, subAssets: [], subFolder: [] },
            state.folders[f._id],
            f,
            { assets: f.assets.map(({_id}) => _id) },
            { items: f.assets},
          ),
        }), {}),
      },
    };
  }

  case LOAD_ASSET_SUCCESS: {
    return {
      ...state,
      assets: {
        ...state.assets,
        [action.payload._id]: action.payload,
      },
    };
  }

  case LOAD_ASSET_PREVIEW_SUCCESS: {
    return {
      ...state,
      assetPreviews: {
        ...state.assetPreviews,
        [action.id]: action.payload,
      },
    };
  }

  case LOAD_ASSET_ALL_SUCCESS: {
    return {
      ...state,
      assets: {
        ...state.assets,
        ...action.payload.assets.reduce((acc, i) => {
          return Object.assign(acc, {[i._id]: i});
        }, {}),
      },
      folders: {
        ...state.folders,
        ...action.payload.folders.reduce((acc, i) => Object.assign(acc, {
          [i._id]: Object.assign(
            {load: true, subAssets: [], subFolders: []},
            i,
          ),
        }), {}),
      },
    };
  }

  case ASSET_WATCH_TIME: {
    const id = action.id;
    const watchTime = action.payload;
    const page = action.page;
    const preStats = state.assetStats[id];

    const stats = Object.assign({}, preStats, {
      totalWatchTime: _.get(preStats, "totalWatchTime", 0) + watchTime,
      watchTime,
      currentPage: page,
    });

    if (page) {
      const prePages = _.get(preStats, "pages", {});
      stats.pages = Object.assign({}, prePages, {
        [page]: {
          totalWatchTime: _.get(prePages, `${page}.totalWatchTime`, 0) + watchTime,
          watchTime
        }
      });
    }

    return {
      ...state,
      assetStats: {
        ...state.assetStats,
        [action.id]: stats,
      }
    };
  }

  default: {
    return state;
  }
  }
}
