import { useSelector } from "react-redux";

import { MODULE_NAME } from "./actions";
import { IfolerState, Iasset, IassetPreivew, IassetStats } from "./reducer";

interface Istate {
  [MODULE_NAME]: IfolerState;
}

export function useAssetSelector(aid: string): null | Iasset {
  return useSelector((state: Istate) => {
    return state[MODULE_NAME].assets[aid];
  });
}

export function useAssetsSelector(): object  {
  return useSelector((state: Istate) => {
    return state[MODULE_NAME].assets;
  });
}

export function useAssetByIdsSelector(ids: string[]): Iasset[]  {
  return useSelector((state: Istate) => {
    return ids.map(id => {
      return state[MODULE_NAME].assets[id];
    });
  });
}

export function useAssetPreviewSelector(aid: string): null | IassetPreivew {
  return useSelector((state: Istate) => {
    return state[MODULE_NAME].assetPreviews[aid];
  });
}

export function useAssetStatsSelector(aid: string): null | IassetStats {
  return useSelector((state: Istate) => {
    return state[MODULE_NAME].assetStats[aid];
  });
}
