import {
  createMuiTheme,
  CssBaseline,
  Theme,
  responsiveFontSizes,
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { ThemeOptions } from "@material-ui/core/styles/createMuiTheme";

import _ from "lodash";
import React, {useState, useEffect, Suspense} from "react";
import { Provider } from "react-redux";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import {Route, Switch} from "react-router-dom";
import { parse } from "url";

import * as actions from "./actions";
import * as api from "./api";
import reducer from "./reducer";
import Showcase from "./showcase";
import Dialog from "./dialog";

const composeEnhancers = _.get(window, "__REDUX_DEVTOOLS_EXTENSION_COMPOSE__", compose);
const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(
    thunk.withExtraArgument({
      actions, api,
    })
  )),
);

function Main(): React.ReactElement {
  return (
    <>
      <Dialog />
      <Switch>
        <Route path="/:showcaseId" component={Showcase}/>
      </Switch>
    </>
  );
}

function App(): React.ReactElement {
  const createTheme = (theme: ThemeOptions = {}): Theme => {
    return responsiveFontSizes(createMuiTheme(theme));
  };

  // theme
  const [theme, setTheme] = useState(() => createTheme());
  const [ticket, setTicket] = useState();
  const localUrl = document.location.toString();
  const url = parse(localUrl, true);
  const { query } = url;

  const unionIdItemKey = `unionId`;

  if (query.unionid) {
    let unionid = query.unionid;
    if (Array.isArray(unionid)) {
      unionid = unionid[0];
    }
    localStorage.setItem(unionIdItemKey, unionid);
  }

  if (ticket) {
    const { appId } = ticket;
    const loginUri = `https://api-prod.yangbentong.com/portal/wechat/mobileOAuth?redirect_uri=${encodeURIComponent(localUrl)}`;
    const redirectUri = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${encodeURIComponent(loginUri)}&response_type=code&scope=snsapi_userinfo`;
    window.location.href = redirectUri;
  }

  useEffect(() => {

    // 微信访问
    if (navigator.userAgent.toLowerCase().indexOf('micromessenger') > -1) {
      const unionId = localStorage.getItem(unionIdItemKey);
      if (!unionId) {
        api.fetchJsSdkSignature(localUrl)
        .then(setTicket);
      }
    }
    
    fetch("/theme.json").then((res) => {
      return res.json();
    }).then((themeJson) => {
      setTheme(createTheme(Object.assign({
        mixins: {
          toolbar: {
            minHeight: 64
          }
        }
      }, themeJson)));
    });
  }, []);

  return (
    <Suspense fallback="loading...">
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        <Provider store={store}>
          <Main />
        </Provider>
      </ThemeProvider>
    </Suspense>
  );
}

export default App;
