import React, {
  ReactElement, useRef, useEffect
} from "react";
import {
  makeStyles,
  DialogTitle, DialogContent,
  IconButton,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import {
  BigPlayButton,
  ControlBar,
  CurrentTimeDisplay,
  ForwardControl,
  PlaybackRateMenuButton,
  Player,
  ReplayControl,
  TimeDivider,
  VolumeMenuButton,
} from "video-react";
import _ from "lodash";

import { useCloseDialog } from "../hooks";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: "12px",
    top: "4px"
  },
  dialogContent: {
    backgroundColor: theme.palette.secondary.main,
    display: "flex",
    flexDirection: "column",
    padding: 0,
  },
  videoWraper: {
    margin: "auto",
    width: "100%",
  },
}));

interface IvideoPlayer {
  poster?: string;
  src: string;
  name?: string;
  countWatchTime?: (startTime: number, endTime: number) => void;
}

export function VideoPlayer(props: IvideoPlayer): ReactElement {
  const { poster, src, countWatchTime = _.identity } = props;
  const classes = useStyles({});
  const player = useRef(null);


  useEffect(() => {
    let startTime = 0;
    let endTime = 0;

    if (player) {
      player.current.subscribeToStateChange((state, preState) => {
        // 从暂停到播放
        if ((preState.paused === true) && (state.paused === false)) {
          startTime = state.currentTime;
        }

        if (preState.paused === false) {
          // 从暂停到播放
          if (state.paused === true) {
            endTime = state.currentTime;
            countWatchTime(startTime, endTime);
          }

          // 跳转进度条
          if ((preState.seeking === false) && (state.seeking === true)) {
            countWatchTime(startTime, preState.currentTime);
            startTime = state.currentTime;
          }
        }
      });
    }
  }, [player]);

  return (
    <div className={classes.videoWraper}>
      <Player poster={poster} ref={player}>
        <source src={src} />
        <BigPlayButton position="center"/>
        <ControlBar>
          <ReplayControl seconds={10} order={1.1} />
          <ForwardControl seconds={30} order={1.2} />
          <CurrentTimeDisplay order={4.1} />
          <TimeDivider order={4.2} />
          <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
          <VolumeMenuButton />
        </ControlBar>
      </Player>
    </div>
  );
}

export function VideoDialog(props: IvideoPlayer): ReactElement {
  const {poster, src, name} = props;
  const closeDialog = useCloseDialog();
  const classes = useStyles({});

  return (
    <>
      <DialogTitle>
        {name}
        <IconButton onClick={closeDialog} className={classes.closeButton}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent} >
        <VideoPlayer poster={poster} src={src} {...props} />
      </DialogContent>
    </>
  );
}
