
export const MODULE_NAME = "category";
export const LOAD_CATEGORY_REQUEST = "category/load/request";
export const LOAD_CATEGORY_SUCCESS = "category/load/success";
export const LOAD_CATEGORY_FAILURE = "category/load/failure";
export const LOAD_PRODUCT_REQUEST = "product/load/request";
export const LOAD_PRODUCT_SUCCESS = "product/load/success";
export const LOAD_PRODUCT_FAILURE = "product/load/failure";

export function getCategory(tenantId: string, cid: string) {
  return (dispath, getSate, {api}): void => {
    api.fetchCategory(tenantId, cid)
    .then((res) => {
      dispath({
        type: LOAD_CATEGORY_SUCCESS,
        payload: res,
      });
    });
  };
}

export function getProduct(tenantId: string, pid: string) {
  return (dispath, getSate, {api}): void => {
    api.fetchProduct(tenantId, pid)
    .then((res) => {
      dispath({
        type: LOAD_PRODUCT_SUCCESS,
        payload: res,
      });
    });
  };
}