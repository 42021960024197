import { useSelector } from "react-redux";

import { MODULE_NAME } from "./actions";
import { IarticleState, Iarticle, Itopic } from "./reducer";

interface Istate {
  [MODULE_NAME]: IarticleState;
}

export function useArticleSelector(aid: string): Iarticle {
  return useSelector((state: Istate) => {
    const article = state[MODULE_NAME].articles[aid];
    if (!article) {
      return article;
    }

    return article;
  });
}

export function useTopicSelector(tid: string): Itopic {
  return useSelector((state: Istate) => {
    const topic = state[MODULE_NAME].topics[tid];
    if (!topic) {
      return topic;
    }

    return topic;
  });
}