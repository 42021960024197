
import {
  Theme,
  Typography
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { makeStyles, createStyles } from "@material-ui/styles";

import _ from "lodash";
import React from "react";
import parse from 'html-react-parser';

import { VideoPlayer } from "./videoView";
import { openImageDialog } from "../actions";


const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      padding: theme.spacing(1),
      height: "100%",
      overflowY: "auto",
      "& *": {
        maxWidth: "100%",
        wordBreak: "break-all",
      },
      "& table": {
        borderCollapse: "collapse",
        borderSpacing: 0,
        emptyCells: "show",
        border: "1px solid #ddd",
        width: "100%",
      },
      "& table td": {
        backgroundColor: "transparent",
        borderBottom: "1px solid #ddd",
        borderLeft: "1px solid #ddd",
      }
    },
    contentImg: {
      height: "auto",
      width: "auto",
    },
  });
});

interface Idata {
  content: string;
  classes?: Partial<ReturnType<typeof useStyles>>;
}

export default function RichContent(props: Idata): React.ReactElement {
  const dispatch = useDispatch();
  const { content, classes: injectClasses = {} } = props;
  const classes = useStyles({});

  const openImage = (url: string, alt?: string): void => {
    dispatch(openImageDialog(url, alt));
  };
  const contentParse = parse(content, {
    replace: function(domNode) {

      const { name, attribs, children } = domNode;

      if (name === 'video') {
        const source = children.filter((v) => v.name === 'source')[0];
        const src = _.get(source, 'attribs.src');
        return <VideoPlayer src={src}/>;
      }

      if (name === "img") {
        return <img src={attribs.src} alt={attribs.alt}
          className={classes.contentImg}
          onClick={(): void => openImage(attribs.src, attribs.alt)}
        />;
      }
    }
  });
  return (
    <Typography component="div"
      className={`${classes.root} ${injectClasses.root}`}>
      {contentParse}
    </Typography>
  );
}