import { useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  getShowcase,
  toggleDrawer,
  showcaseEvent,
  IshowcaseEventData,
  showcaseEventEnum,
  setCurrentContentId
} from "./actions";
import { Ishowcase } from "./reducer";
import { useShowcaseSelector } from "./selectors";

export function useGetShowcase(showcaseId): Ishowcase {
  const dispatch = useDispatch();
  const showcase = useShowcaseSelector();

  useEffect(() => {
    dispatch(getShowcase(showcaseId));
  }, [showcaseId]);

  return showcase;
}

export function useToggleDrawer(): () => void {
  const dispatch = useDispatch();

  return (): void => { dispatch(toggleDrawer()); };
}

export function useSendShowcaseEvent(sid: string): (type: showcaseEventEnum, data?: IshowcaseEventData) => void {
  const dispatch = useDispatch();

  return (type: showcaseEventEnum, data?: IshowcaseEventData): void => {
    dispatch(showcaseEvent(sid, type, data));
  };
}

export function useSetCurrentContentId(): (id: string) => void {
  const dispatch = useDispatch();

  return (id: string): void => {
    dispatch(setCurrentContentId(id));
  };
}
