import { useDispatch } from "react-redux";

import { openAssetDetailDialog, closeDialog } from "./actions";

export function useOpenAssetDetail(): (id: string) => void {
  const dispatch = useDispatch();

  return (id: string): void => {
    dispatch(openAssetDetailDialog(id));
  };
}

export function useCloseDialog (): () => void {
  const dispatch = useDispatch();

  return (): void => { dispatch(closeDialog()); };
}
