import {
  CircularProgress,
  DialogContent,
  Fab,
  Paper,
  Theme,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { Add, Remove, Close } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";

import _ from "lodash";
import React, { useMemo, useState, ReactElement } from "react";
import { Document, Page } from "react-pdf/dist/entry.webpack";

import { useWindowSize } from "../utils/hooks";
import { useCloseDialog } from "../hooks";

import { useLoadAssetPreview } from "./hooks";
import { Iasset } from "./reducer";

const PDFOPTIONS = {
  cMapPacked: true,
  cMapUrl: "cmaps/",
};
const PADDINGSIZE = 8;

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: "absolute",
    right: "12px",
    top: "4px"
  },
  dialogContent: {
    "& .react-pdf__Document": {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
    },
    "& .react-pdf__Page": {
      "&:-webkit-full-screen": {
        "& .react-pdf__Page__canvas": {
        },
        "backgroundColor": "#f5f5f5",
        "display": "flex",
        "flexDirection": "column",
      },
      "display": "block",
      "margin": "auto",
      "marginBottom": theme.spacing(4),
    },
    "backgroundColor": "#f5f5f5",
    "height": "100%",
    "padding": `${theme.spacing(4)}px 0 0`,
    "width": "100%",
  },
  fabAdd: {
    bottom: theme.spacing(8) + 50,
    position: "fixed",
    right: theme.spacing(8),
  },
  fabSub: {
    bottom: theme.spacing(8),
    position: "fixed",
    right: theme.spacing(8),
  },
  loading: {
    alignItems: "center",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    width: "100%",
  },
  loadingPaper: {
    marginBottom: theme.spacing(4),
    position: "relative",
  },
}));

interface Iloading {
  classes: ReturnType<typeof useStyles>;
  image: string;
  windowSize: {width: number; height: number};
}
function Loading({classes, image, windowSize}: Iloading): ReactElement {
  return (
    <div className={classes.loading}>
      <img src={image} />
      <CircularProgress
        style={{
          left: windowSize.width / 2 - 20,
          position: "absolute",
          top: windowSize.height / 2 - 20,
        }}
      />
    </div>
  );
}

interface IpdfjsViewer {
  classes: ReturnType<typeof useStyles>;
  file: string;
}
function PdfjsViewer({classes, file}: IpdfjsViewer): ReactElement {
  const pdfViewerUri = "https://assets-cdn.yangbentong.com/viewer/std/1.0.41/pdfjs/web/viewer.html";
  const pdfUri = `${pdfViewerUri}?file=${file}#pagemode=thumbs`;

  return (
    <DialogContent className={classes.dialogContent}
      style={{padding: 0, overflowY: "hidden"}}
    >
      <iframe src={pdfUri} style={{height: "100%", width: "100%"}} />
    </DialogContent>
  );
}

function IvyPDFPage(props): ReactElement {
  const {
    classes, scale, page, height, width, thumbnails,
    shouldRander, onLoadPageSuccess, onRenderSuccess,
  } = props;
  const thumbnail = _.get(thumbnails, "small", "");

  return useMemo(() => {
    if (!shouldRander) {
      return <Paper className={classes.loadingPaper} style={{height, width}} >
        <img src={thumbnail} alt={`${page}.thumb`} style={{ height, width }}/>
      </Paper>;
    }

    return (
      <Page loading={null}
        renderAnnotationLayer={false}
        renderTextLayer={false}
        // ref={ref}
        // onClick={onClick}
        onLoadSuccess={onLoadPageSuccess}
        onRenderSuccess={onRenderSuccess}
        pageIndex={page}
        scale={scale}
      />
    );
  },
  [scale, shouldRander, height],
  );
}

interface IpdfView {
  asset: Iasset;
  file: string;
  thumbnail: string;
  isSmallScreen: boolean;
}
export default function PDFView(props: IpdfView): ReactElement {
  const {asset, file, thumbnail, isSmallScreen} = props;
  const {_id,
    current: { name, size, info: { page_num: PageNumbers } }
  } = asset;

  const classes = useStyles({});
  const windowSize = useWindowSize();
  const contentHeight = windowSize.height - 64 - 32;
  const contentWidth = windowSize.width - PADDINGSIZE * 4;

  const thumbnails = useLoadAssetPreview(_id) || [];
  const closeDialog = useCloseDialog();

  const [pages, setPages] = useState(Array.from(
    {length: PageNumbers},
    () => ({
      height: 0,
      load: false,
      originalHeight: 0,
      originalWidth: 0,
      render: false,
      width: 0,
    }),
  ));

  const [scale, setScale] = useState(0.8);
  const addScale = (n): void => setScale((pre) => {
    if (pre + n < 0.2) {
      return pre;
    }

    setPages((pp) => pp.map((p) => Object.assign(p, {render: false})));
    return pre + n;
  });

  const onLoadPageSuccess = (index) => (PDFPage): void => {
    setPages((pre) => {
      const preLoad = pre[index].load;

      pre[index] = {
        height: PDFPage.height,
        load: true,
        originalHeight: PDFPage.originalHeight,
        originalWidth: PDFPage.originalWidth,
        render: pre[index].render,
        width: PDFPage.width,
      };

      if (preLoad) {
        return pre;
      }

      // 第一页加载完之后计算初始 scale，之后不再调整，除非点击 zoom button
      if (index === 0) {
        setScale(_.min([
          contentHeight / PDFPage.originalHeight,
          contentWidth / PDFPage.originalWidth,
        ]));
      }

      return [...pre];
    });
  };

  const onRenderSuccess = (index) => (): void => {
    setPages((pre) => {
      if (pre[index].render) {
        return pre;
      }

      pre[index].render = true;
      return [...pre];
    });
  };

  const Title = (): ReactElement => (
    <DialogTitle>
      {name}
      <IconButton onClick={closeDialog} className={classes.closeButton}>
        <Close />
      </IconButton>
    </DialogTitle>
  );

  if (!isSmallScreen) {
    return <>
      <Title />
      <PdfjsViewer classes={classes} file={file} />
    </>;
  }

  return (
    <>
      <Title />
      <DialogContent className={classes.dialogContent}  >
        <Document file={file}
          options={{...PDFOPTIONS, length: size}}
          loading={<Loading classes={classes}
            image={thumbnail} windowSize={windowSize}
          />}
        >
          {Array.from({length: PageNumbers}, (e, index) => {
            const prePageNumber = index === 0 ? 0 : (index - 1);
            // const shouldRander = index < 2;
            const shouldRander = index === 0
              ? true
              : pages[prePageNumber].render;

            return (
              <IvyPDFPage key={index} page={index} scale={scale}
                classes={classes} thumbnails={thumbnails[index]}
                width={pages[index].width || pages[0].originalWidth * scale}
                height={pages[index].height || pages[0].originalHeight * scale}
                shouldRander={shouldRander}
                onRenderSuccess={onRenderSuccess(index)}
                onLoadPageSuccess={onLoadPageSuccess(index)}
              />
            );
          })}
        </Document>
        <Fab size="small" className={classes.fabSub}
          onClick={(): void => addScale(-0.2)}
        ><Remove /></Fab>
        <Fab size="small" className={classes.fabAdd}
          onClick={(): void => addScale(0.2)}
        ><Add /></Fab>
      </DialogContent>
    </>
  );
}
