
import _ from "lodash";
import { useSelector } from "react-redux";

import { MODULE_NAME } from "./actions";
import { IshowcaseState, Ishowcase, Ivcard } from "./reducer";

interface Istate {
  [MODULE_NAME]: IshowcaseState;
}

export const useShowcaseSelector = (): Ishowcase => {
  return useSelector((state: Istate) => state[MODULE_NAME].showcase);
};

export function useShowcaseSubjectSelector(): null | string {
  return _.get(useShowcaseSelector(), "subject");
}

export function useShowcaseVcardSelector(): Ivcard {
  return _.get(useShowcaseSelector(), "vcard");
}

export function useDrawerSelector(): boolean {
  return useSelector((state: Istate) => {
    return state[MODULE_NAME].drawerOpen;
  });
}

export function useCurrentContentIdSelector(): null | string {
  return useSelector((state: Istate) => {
    return state[MODULE_NAME].currentContentId;
  });
}
