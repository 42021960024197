import { DialogProps } from "@material-ui/core/Dialog";

import {combineReducers} from "redux";

import {
  MODULE_NAME as MAIN_MODULE_NAME,
  SET_TENANTID,
  SET_CURRENT_CATEGORY_ID,
  DIALOGS,
  OPEN_IMAGE_DIALOG,
  CLOSE_DIALOG,
  OPEN_ASSET_DETAIL_DIALOG,
} from "./actions";

import folder, { MODULE_NAME as FOLDER_MODULE_NAME } from "./folder/reducer";
import articles, { MODULE_NAME as ARTICLE_MODULE_NAME } from "./topic/reducer";
import category, { MODULE_NAME as CATEGORY_MODULE_NAME } from "./category/reducer";
import showcase, { MODULE_NAME as SHOWCASE_MODULE_NAME } from "./showcase/reducer";

export interface Idialog {
  fullScreen: null | boolean;
  type: DIALOGS;
  maxWidth: DialogProps["maxWidth"];
  open: boolean;
  data: null | object;
}
export interface ImainState {
  currentCategoryId: null | string;
  tenantId: null | string;
  dialog: Idialog;
}
const initMainState: ImainState = {
  dialog: {
    fullScreen: null,
    open: false,
    maxWidth: "md",
    type: DIALOGS.INIT,
    data: null,
  },
  currentCategoryId: null,
  tenantId: null,
};

function reducer(state = initMainState, action): ImainState {
  switch (action.type) {

  case SET_TENANTID: {
    return {
      ...state,
      tenantId: action.payload,
    };
  }

  case SET_CURRENT_CATEGORY_ID: {
    return {
      ...state,
      currentCategoryId: action.payload,
    };
  }

  case CLOSE_DIALOG: {
    return {
      ...state,
      dialog: {
        ...state.dialog,
        open: false,
        data: null,
        type: DIALOGS.INIT,
      }
    };
  }

  case OPEN_IMAGE_DIALOG: {
    return {
      ...state,
      dialog: {
        fullScreen: action.fullScreen,
        open: true,
        maxWidth: action.maxWidth,
        type: action.dialog,
        data: action.payload,
      },
    };
  }

  case OPEN_ASSET_DETAIL_DIALOG: {
    return {
      ...state,
      dialog: {
        fullScreen: action.fullScreen,
        open: true,
        maxWidth: action.maxWidth,
        type: action.dialog,
        data: action.payload,
      },
    };
  }

  default: {
    return state;
  }
  }
}

export default combineReducers({
  [MAIN_MODULE_NAME]: reducer,
  [ARTICLE_MODULE_NAME]: articles,
  [FOLDER_MODULE_NAME]: folder,
  [CATEGORY_MODULE_NAME]: category,
  [SHOWCASE_MODULE_NAME]: showcase,
});
