import {
  Dialog,
  Slide,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions/transition";
import React from "react";
import { useDispatch } from "react-redux";

import { closeDialog, DIALOGS } from "./actions";
import { useSmallScreen, useDialogSelector } from "./selectors";

import imageDialog from "./components/imageDialog";
import AssetDetail from "./folder/assetDetail";

const InitContent = ( ): React.ReactElement => {
  return null;
};

const componentsMap = {
  [DIALOGS.IMAGE_DIALOG]: imageDialog,
  [DIALOGS.INIT]: InitContent,
  [DIALOGS.ASSET_DETAIL]: AssetDetail,
};


const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function IVYDialog(): React.ReactElement {
  const dispatch = useDispatch();
  const onClose = (): void => { dispatch(closeDialog()); };
  const dialog = useDialogSelector();
  const isSmallScreen = useSmallScreen();

  const {type, data, open, maxWidth} = dialog;

  const Content = componentsMap[type];
  if (!Content) {
    throw new Error("dialog content not found!");
  }

  const fullScreen = (dialog.fullScreen === null)
    ? isSmallScreen
    : dialog.fullScreen;

  return (
    <Dialog fullScreen={fullScreen} maxWidth={maxWidth}
      open={open} onClose={onClose}
      keepMounted={false} TransitionComponent={Transition}
    >
      <Content data={data}/>
    </Dialog>
  );
}
