import {
  Box, Card, CardActions, CardContent,
  CardMedia, CircularProgress,
  Grid, IconButton,
  List, ListItem, ListItemAvatar,
  ListItemText, ListItemSecondaryAction,
  Menu, MenuItem,
  Tooltip, Typography, Divider,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { MoreHoriz } from "@material-ui/icons";

import _ from "lodash";
import clsx from "clsx";
import React, { Dispatch, ReactElement, SetStateAction, useState } from "react";

import { usePerRowItemPercentageSelector } from "../utils/hooks";

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    height: "48px",
    width: "48px",
    objectFit: "contain",
    marginRight: theme.spacing(1),
  },
  card: {
    background: "none",
    width: "200px",
  },
  cardAction: {
    justifyContent: "center",
    padding: 0,
    paddingTop: theme.spacing(1),
  },
  cardActionButton: {
    color: theme.palette.grey[50],
  },
  cardContent: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    padding: 0,
    paddingTop: theme.spacing(1),
    textAlign: "center",
  },
  cardImage: {
    backgroundSize: "contain",
    height: "160px",
  },
  cardImageItem: {},
  cardImageFolder: {
    height: "120px",
    margin: "0 auto",
    width: "120px",
  },
  cardWrapper: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("lg")]: {
      alignItems: "flex-end",
      justifyContent: "center",
    },
  },
  main: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4),
    },
  },
}));

export interface IfolderItemParams {
  data: {
    _id: string;
    primaryText: string;
    cover: string;
    secondaryText: string;
    type: "item" | "folder";
  };
  onClick: () => void;
  onClickMenu?: Dispatch<SetStateAction<{
    anchorEL: HTMLElement; items: ReactElement[];
  }>>;
  actions: Array<{
    name: string;
    icon: ReactElement;
    onClick: () => void;
  }>;
  itemWidth?: number;
}
export type IFolderItem = (content: IfolderItemParams) => ReactElement;

const IVYListItem: IFolderItem = (props: IfolderItemParams) => {
  const {data, onClick, actions = [], onClickMenu} = props;
  const classes = useStyles({});
  const {primaryText, cover, secondaryText} = data;

  const MenuItems = actions.map(({name, onClick: onActionClick}) => (
    <MenuItem key={name} button onClick={onActionClick}>{name}</MenuItem>
  ));

  return (
    <>
      <ListItem>
        <ListItemAvatar >
          <img src={cover} className={classes.avatar}
            onClick={onClick}/>
        </ListItemAvatar>
        <ListItemText
          primary={primaryText}
          secondary={secondaryText}
          onClick={onClick}
        />
        <ListItemSecondaryAction>
          {actions.length !== 0 && <IconButton edge="end" aria-label="More"
            onClick={(e): void => onClickMenu({
              anchorEL: e.currentTarget, items: MenuItems,
            })}
          >
            <MoreHoriz />
          </IconButton>}
        </ListItemSecondaryAction>
      </ListItem>
      <Divider component="li" variant="inset" />
    </>
  );
};

const IVYGridItem: IFolderItem = (props: IfolderItemParams) => {
  const {data, itemWidth, onClick, actions = []} = props;
  const classes = useStyles({});
  const [actionOpacity, setActionOpacity] = useState(0);
  const {primaryText, cover, secondaryText, type} = data;

  return (
    <Grid item sm="auto" className={classes.cardWrapper}
      style={{width: itemWidth ? itemWidth : "none"}}
    >
      <Card elevation={0} className={classes.card} title={name}
        onMouseOver={(): void => setActionOpacity(100)}
        onMouseOut={(): void => setActionOpacity(0)}
      >
        <CardMedia className={clsx(classes.cardImage, {
          [classes.cardImageFolder]: type === "folder",
          [classes.cardImageItem]: type === "item",
        })}
        image={cover}
        onClick={onClick}
        />
        <CardContent className={classes.cardContent}>
          <Typography style={{maxWidth: "100%"}}>
            {primaryText}
          </Typography>
          <Typography variant="subtitle2">{secondaryText}</Typography>
        </CardContent>
        <CardActions className={classes.cardAction} style={{opacity: actionOpacity}}>
          {actions.map(({name, icon, onClick: onClinkAction}) => <Tooltip
            key={name} title={name}>
            <IconButton size="small" className={classes.cardActionButton}
              onClick={onClinkAction}> {icon} </IconButton></Tooltip>)}
        </CardActions>
      </Card>
    </Grid>
  );
};

export function Loading(): React.ReactElement {
  const classes = useStyles({});
  return (
    <Box className={classes.main} display="flex" flexDirection="column">
      <Box margin="auto">
        <CircularProgress color="primary" />
      </Box>
    </Box>
  );
}

export function Empty(): React.ReactElement {
  const classes = useStyles({});
  return (
    <Box className={classes.main} display="flex" flexDirection="column">
    </Box>
  );
}

type Classes = ReturnType<typeof useStyles>;
interface IfolderParams {
  classes?: Partial<Classes>;
  isSmallScreen: boolean;
  foldersFactory: (Item: IFolderItem) => ReactElement[];
  itemsFactory: (Item: IFolderItem) => ReactElement[];
}

export function Folder(props: IfolderParams): React.ReactElement {
  const {isSmallScreen, foldersFactory, itemsFactory} = props;
  let { classes } = props;
  classes = _.extend({}, useStyles({}), classes);
  const {
    containerRef, percentage: itemWidth,
  } = usePerRowItemPercentageSelector(212);

  const [menu, setMenu] = useState({ anchorEL: null, items: null });
  const closeMenu = (): void => setMenu((pre) => Object.assign(
    {}, pre, {anchorEL: null}
  ));

  const Item = isSmallScreen ? IVYListItem : IVYGridItem;

  const folders = foldersFactory(Item);
  const items = itemsFactory(Item);

  if (isSmallScreen) {
    return (
      <div className={classes.main}>
        <List>
          {folders.map((f) => React.cloneElement(f, { onClickMenu: setMenu }))}
          {items.map((a) => React.cloneElement(a, { onClickMenu: setMenu }))}
        </List>
        <Menu keepMounted anchorEl={menu.anchorEL}
          open={Boolean(menu.anchorEL)} onClose={closeMenu}
          onClick={closeMenu}
        >
          {menu.items}
        </Menu>
      </div>
    );
  }

  return (
    <div className={classes.main} ref={containerRef} >
      <Grid container spacing={2} justify="flex-start" alignItems="baseline">
        {folders.map((f) => React.cloneElement(f, { itemWidth }))}
        {items.map((a) => React.cloneElement(a, { itemWidth }))}
      </Grid>
    </div>
  );
}
